<template>
<v-layout column>
    <v-flex>
        <h2>
            <v-icon class="mr-1">mdi-file-chart</v-icon>Reports
        </h2>
        <!-- <page-header v-model="search" title="Reports" permission='viewSettings'></page-header> -->
    </v-flex>
    <v-flex>
        <v-container :class="$vuetify.breakpoint.xsOnly?'':'mx-12'">
            <v-layout wrap>
                <v-flex v-for="report in reports" :key="report.id" xs12 sm5 class="ma-3" text-center>
                    <v-card @click="$router.push({name:report.path})" v-if="isAllowed(report.permission)">
                        <v-card-text>
                            <v-icon x-large :style="themeInverted">{{report.icon}}</v-icon>
                            <h1>{{report.title}}</h1>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-flex>
</v-layout>
</template>

<script>
export default {
    data() {
        return {
            reports: [{
                    id: "stock",
                    title: "Stock Report",
                    icon: "mdi-chart-donut-variant",
                    path: "StockReport",
                    permission:"viewStockReport"
                },
                {
                    id: "daywiseSell",
                    title: "Daywise Sell Report",
                    icon: "mdi-currency-inr",
                    path: "DaywiseSaleReport",
                    permission:"viewDaywiseSaleReport"
                },
                {
                    id: "productsold",
                    title: "Product Sold Report",
                    icon: "mdi-chart-bar",
                    path: "ProductSoldReport",
                    permission:"viewProductSoldReport"
                },
                {
                    id: "salesReport",
                    title: "Sales Report",
                    icon: "mdi-chart-areaspline",
                    path: "SalesReport",
                    permission:"viewSalesReport"
                },
                {
                    id: "productsToOrder",
                    title: "Products To Order",
                    icon: "mdi-dolly",
                    path: "ProductsToOrder",
                    permission:"viewProductsToOrder"
                },
                {
                    id: "expenseAndIncomeReport",
                    title: "Expense and Income Report",
                    icon: "mdi-credit-card-outline",
                    path: "ExpenseAndIncomeReport",
                    permission:"viewIncomeExpenseReport"
                },
                {
                    id: "vendorStatsReport",
                    title: "Vendor Statistics Report",
                    icon: "mdi-account",
                    path: "VendorStatsReport",
                    permission:"viewTopVendorsStatsReport"
                }
            ]
        };
    }
};
</script>

<style scoped>
.v-icon.v-icon{
    font-size: 56px !important
}
</style>
